module controllers {
    'use strict';
    export module customs {
        interface IClearingInstructionReconDetailUpdateScope extends ng.IScope {
        }

        interface IClearingInstructionReconDetailUpdateParams extends ng.ui.IStateParamsService {
        }

        interface IClearingInstructionReconDetailUpdateBulkUpdateScope extends ng.IScope {
            Close(): void;
            Submit(): void;
            FieldNames: Array<string>;
            FieldNameModel: string;
            Comment: string;
            CurrentReconId: number;
        }

        export class clearingInstructionReconDetailUpdate {
            static $inject = [
                '$scope',
                '$rootScope',
                'generalService',
                '$q',
                '$stateParams',
                '$anchorScroll',
                '$state',
                'bsLoadingOverlayService',
                '$timeout',
                '$transitions',
                'documentRepositoryService',
                'customsService',
                'classificationValueService',
                '$uibModal',
                'statusService',
                'reportService'
            ];

            public TransactionStatusHistoryList: Array<interfaces.master.ITransactionStatusHistory>
            public ClearingInstructionRecon: interfaces.customs.IClearingInstructionRecon;
            public ClearingInstructionReconHeaderDetailList: interfaces.customs.IClearingInstructionReconHeaderDetailList; 
            
            cusId: number = 0;
            goodsDeclarationId: number = 0;

            selectedTab: number = 0;

            apiCusHeadList: uiGrid.IGridApi;
            selectedCus: uiGrid.IGridRow;

            apiCusLineDetList: uiGrid.IGridApi;

            documentRepository: interfaces.documentManagement.IDocumentRepository;

            IsLoading: boolean = false;
            showAll: boolean = false;

            LockCustomRecon: boolean = false;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            dropdownsObject = {
                CustomsStatusList: Array<interfaces.applicationcore.IDropdownModel>()
            };

            public type: Enum.EnumModelType = Enum.EnumModelType.GoodsDeclaration;
            public referenceId: number;
            public version: number;

            public LockComments: boolean = false;

            constructor(
                private $scope: IClearingInstructionReconDetailUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $stateParams: IClearingInstructionReconDetailUpdateParams,
                private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService,
                public bsLoadingOverlayService,
                public $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                private customsService: interfaces.customs.ICustomsService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private statusService: services.master.statusService,
                private reportService: interfaces.reporting.IReportService) {

                if ($stateParams.cusId === null) {
                    return;
                } else {
                    this.cusId = $stateParams.cusId;
                }

                this.loadControls();
            }

            loadControls() {
                var loadPromises: ng.IPromise<any>[] = [];
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'cus.update'
                },
                    () => {
                        loadPromises.push(this.GetClearingInstructionReconHeaderDetail());
                        loadPromises.push(this.GetCustomsStatusList());

                        return this.$q.all(loadPromises);
                    });
            }

            public gvwCusClearingInstructionReconDetail: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                useExternalPagination: false,
                enableGridMenu: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: false,
                gridMenuShowHideColumns: false,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "Id",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false,
                },
                {
                    name: "FieldName",
                    displayName: "Field",
                    field: "FieldName",
                    width: 250,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "CIValue",
                    displayName: "CI Value",
                    field: "CIValue",
                    width: 200,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "DeclaredValue",
                    displayName: "Declared Value",
                    field: "DeclaredValue",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "IsMatched",
                    displayName: "Matched",
                    field: "IsMatched",
                    width: 80,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-disabled="(row.entity.IsMatchedComplete) || (row.entity.IsMatched && row.entity.Comments)" ng-change="grid.appScope.clearingInstructionReconDetailUpdate.checkBoxChanged(row.entity)">`,
                    enableCellEditOnFocus: false,
                    enableCellEdit: false
                },
                {
                    name: "Comments",
                    displayName: "Comments",
                    field: "Comments",
                    width: 500,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="(row.entity.IsMatchedComplete)" >{{grid.getCellValue(row, col)}}</div>`,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" ng-disabled="(row.entity.IsMatchedComplete)" name="grossmass-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.000001" />
                                    </p>
                                </form>`
                }]
            };

            registerGridApi(gridApi: uiGrid.IGridApi) {

                this.apiCusHeadList = gridApi;
                this.$timeout(() => {
                    if (this.selectedCus)
                        this.apiCusHeadList.selection.selectRow(this.selectedCus.entity);

                    this.apiCusHeadList.edit.on.afterCellEdit(this.$scope, function (rowEntity, colDef, newValue, oldValue) {

                        if (newValue != oldValue) {
                            rowEntity.State = "changed";

                            if (rowEntity.IsMatched && rowEntity.Comments) {

                                rowEntity.IsMatchedComplete = true;
                            }
                        }
                    })
                });
            }

            public gvwCusClearingInstructionReconDetailLineDetails: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                useExternalPagination: false,
                enableGridMenu: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: false,
                gridMenuShowHideColumns: false,
                onRegisterApi: (gridApi) => { this.registerGridApiLineDetails(gridApi) },
                columnDefs: [{
                    name: "Id",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "SequenceNumber",
                    displayName: "#",
                    field: "SequenceNumber",
                    width: 60,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "InvoiceNumber",
                    displayName: "Invoice Number",
                    field: "InvoiceNumber",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "PONumber",
                    displayName: "PO Number",
                    field: "PONumber",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "ProductCode",
                    displayName: "Product Code",
                    field: "ProductCode",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "FieldName",
                    displayName: "Field",
                    field: "FieldName",
                    width: 150,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "CIValue",
                    displayName: "CI Value",
                    field: "CIValue",
                    width: 150,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "DeclaredValue",
                    displayName: "Declared Value",
                    field: "DeclaredValue",
                    width: 150,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "IsMatched",
                    displayName: "Matched",
                    field: "IsMatched",
                    width: 80,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                           <div class="input-group-sm">
                               <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                           </div>
                        </div>`,
                    cellTemplate: `<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-disabled="(row.entity.IsMatchedComplete) || (row.entity.IsMatched && row.entity.Comments)" ng-change="grid.appScope.clearingInstructionReconDetailUpdate.checkBoxChanged(row.entity)">`,
                    enableCellEditOnFocus: false,
                    enableCellEdit: false
                },
                {
                    name: "Comments",
                    displayName: "Comments",
                    field: "Comments",
                    width: 500,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="(row.entity.IsMatchedComplete)" >{{grid.getCellValue(row, col)}}</div>`,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="text" ng-disabled="(row.entity.IsMatchedComplete)" name="grossmass-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.000001" />
                                </p>
                            </form>`
                }]
            };

            registerGridApiLineDetails(gridApi: uiGrid.IGridApi) {
                this.apiCusLineDetList = gridApi;

                this.apiCusLineDetList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiCusLineDetList.selection.selectRow(gridSelection.row.entity);
                });
            }

            CusListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;
            }

            CusListShortChange(gridApi) {
            }

            CusListFilterChange(gridApi: uiGrid.IGridApi) {
            }   

            GetClearingInstructionReconHeaderDetail() {

                this.IsLoading = true;
                return this.customsService.GetRecon(this.cusId).get((data) => {

                    this.ClearingInstructionRecon = data;

                    if (this.ClearingInstructionRecon) {

                        this.gvwCusClearingInstructionReconDetail.data = this.ClearingInstructionRecon.HeaderDetailList;
                        this.gvwCusClearingInstructionReconDetailLineDetails.data = this.ClearingInstructionRecon.DetailDetailList;
                        this.goodsDeclarationId = this.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.GoodsDeclarationId;
                        this.referenceId = this.goodsDeclarationId;
                        this.version = this.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.GoodsDeclarationVersion;
                        this.TransactionStatusHistoryList = this.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.StatusHistory;


                        if (this.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.StatusId === 609)
                        {
                            var AutoMatched  =  <interfaces.applicationcore.IDropdownModel> {Id:609,Code:'',Description:'Auto Matched',Display:'Auto Matched'};
                            this.dropdownsObject.CustomsStatusList.push(AutoMatched);
                        }
                    }

                    this.setLockdown();

                    this.getDocuments(this.showAll);

                }, (data) => {
                    this.IsLoading = false;
                    this.gvwCusClearingInstructionReconDetail.data = [];
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            GetCustomsStatusList() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.CustomsRecon.CustomsStatus
                }, (resultList) => {

                    this.dropdownsObject.CustomsStatusList = resultList;

                    var i: number;

                    for (i = 0; i < this.dropdownsObject.CustomsStatusList.length; i++) {
                        if (this.dropdownsObject.CustomsStatusList[i].Id == 609 || this.dropdownsObject.CustomsStatusList[i].Id == 607) {
                            this.dropdownsObject.CustomsStatusList.splice(i,1);
                        }
                    }

                    if (this.ClearingInstructionRecon) {
                        if (this.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.StatusId === 609)
                        {
                            var AutoMatched  =  <interfaces.applicationcore.IDropdownModel> {Id:609,Code:'',Description:'Auto Matched',Display:'Auto Matched'};
                            this.dropdownsObject.CustomsStatusList.push(AutoMatched);
                        }
                        this.IsLoading = false;
                    }

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadDocuments() {
                if (this.version && this.type && this.referenceId) {

                    return this.reportService.getDocumentList(this.type, this.cusId).query(() => { },
                        (failureMessage) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                        }).$promise;
                }
            }

            ViewClearingInstructionHistory(internationalTrade: interfaces.purchase.IPurchaseOrderNationalMeasureCode) {
                this.IsLoading = true;

                var modalInstance = this.$uibModal.open({
                    animation: true,
                    templateUrl: 'templates/modules/applicationmain/master/statusControlViewHistory.html',
                    controller: ['$uibModalInstance', 'TransactionStatusHistoryList', '$scope', ($modalInstance, TransactionStatusHistoryList, $scope) => {
                        $scope.TransactionStatusHistoryList = TransactionStatusHistoryList;
                    }],
                    size: "lg",
                    resolve: {
                        TransactionStatusHistoryList: () => {
                            return this.TransactionStatusHistoryList
                        }
                    }
                });

                modalInstance.result.then(() => { }, () => { })
            }

            setLockdown() {
                var statusId: number = this.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.StatusId;

                if (statusId === 609 || statusId === 612) {
                    this.LockCustomRecon = true;
                }
            }

            RerunRecon()
            {
                if (this.LockCustomRecon)
                {
                    return;
                }

                this.IsLoading = true;
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'cus.update'
                }, () => {

                    return this.customsService.RerunRecon(
                        this.goodsDeclarationId
                    ).save({

                    }, (result: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(result);

                        this.IsLoading = false;
                        if (!result.HasErrorMessage) {
                        }

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                });
            }

            Submit(close: boolean) {
                this.IsLoading = true;

                var terminateFunction = false;
                var HeaderDetailList: interfaces.customs.IClearingInstructionReconHeaderDetailList[] = [];
                var DetailDetailList: interfaces.customs.IClearingInstructionReconDetailDetailList[] = [];

                if (this.apiCusHeadList) {
                    if (this.apiCusHeadList.rowEdit.getDirtyRows().length > 0) {
                        HeaderDetailList = _.map(this.apiCusHeadList.rowEdit.getDirtyRows(), (n) => {
                            return {
                                Id: n.entity["Id"],
                                FieldName: n.entity["FieldName"],
                                CIValue: n.entity["CIValue"],
                                DeclaredValue: n.entity["DeclaredValue"],
                                IsMatched: n.entity["IsMatched"],
                                Comments: n.entity["Comments"],
                                IsMatchedComplete: n.entity["IsMatchedComplete"]
                            };
                        });
                    }

                    _.forEach(HeaderDetailList, (value) => {
                        if (value.IsMatched && !value.Comments) {
                            this.generalService.displayMessage("Please enter a comment for items that are Matched in the General Tab.", Enum.EnumMessageType.Warning);
                            terminateFunction = true;
                            return;
                        }
                    });
                }

                if (this.apiCusLineDetList) {
                    if (this.apiCusLineDetList.rowEdit.getDirtyRows().length > 0) {
                        DetailDetailList = _.map(this.apiCusLineDetList.rowEdit.getDirtyRows(), (n) => {
                            return {
                                Id: n.entity["Id"],
                                FieldName: n.entity["FieldName"],
                                CIValue: n.entity["CIValue"],
                                DeclaredValue: n.entity["DeclaredValue"],
                                IsMatched: n.entity["IsMatched"],
                                Comments: n.entity["Comments"],
                                SequenceNumber: n.entity["SequenceNumber"],
                                InvoiceNumber: n.entity["InvoiceNumber"],
                                PONumber: n.entity["PONumber"],
                                ProductCode: n.entity["ProductCode"],
                                GoodsDecLineId: n.entity["GoodsDecLineId"],
                                IsMatchedComplete: n.entity["IsMatchedComplete"]
                            };
                        });

                        _.forEach(DetailDetailList, (value) => {
                            if (value.IsMatched && !value.Comments) {
                                this.generalService.displayMessage("Please enter a comment for items that are Matched in the Line Details Tab.", Enum.EnumMessageType.Warning);
                                terminateFunction = true;
                                return;
                            }
                        });
                    }
                }

                if(terminateFunction)
                {
                    return;
                }
                else
                {
                    this.ClearingInstructionRecon.HeaderDetailList = HeaderDetailList;
                    this.ClearingInstructionRecon.DetailDetailList = DetailDetailList;

                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'cus.update'
                    }, () => {
                        return this.customsService.Submit().save({}, this.ClearingInstructionRecon, (result) => {
                            this.generalService.displayMessageHandler(result);

                            if (close)
                                this.Close();
                            else
                                this.loadControls();

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                }
            }

            ShowBulkUpdateAsMatched() {
                this.BulkUpdateAsMatched(this.cusId, this.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.FieldNames);
            }

            BulkUpdateAsMatched(ciId: number, fieldNames: Array<string>) {

                var modalInstance = this.$uibModal.open({
                    animation: true,
                    size: 'lg',
                    template: `
                    <div class="modal-header">
                        <strong class="modal-title">Bulk Update Customs Recon Line Details</strong>
                    </div>
                    <div class="modal-body">
                        <gts-message-display messages="Messages"></gts-message-display>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="manifest.combine">
                            <div class="gts-padding">
                                {{CiId}}
                                {{ciId}}
                                <div class="row">
				                    <div class="row vertical-align">
					                    <div class="col-md-4">
                                            <req></req>
						                    <label>Field Name</label>
					                    </div>
					                    <div class="col-md-6">
						                    <p>
							                    <select class="form-control" ng-model="FieldNameModel">
								                    <option ng-repeat="fieldname in FieldNames" ng-value="fieldname">
									                    {{fieldname}}
								                    </option>
							                    </select>
						                    </p>
					                    </div>
				                    </div>
			                    </div>
			                    <div class="row">
				                    <div class="row vertical-align">
					                    <div class="col-md-4">
                                            <req></req>
						                    <label>Comments</label>
					                    </div>
					                    <div class="col-md-6">
						                    <textarea rows="3" ng-model="Comment" class="form-control"></textarea>
					                    </div>
				                    </div>
			                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="pull-right">
                            <button ng-disabled="(!FieldNameModel || FieldNameModel.length == 0) || (!Comment || Comment.length == 0)" type="button" class="btn btn-primary" ng-click="Submit()">Submit</button>
                            <button type="button" class="btn btn-info" ng-click="Close()">Close</button>
                        </div>
                    </div>`,
                    controller: ['$uibModalInstance', '$scope', "bsLoadingOverlayService", "generalService",
                        ($modalInstance: ng.ui.bootstrap.IModalInstanceService, $scope: IClearingInstructionReconDetailUpdateBulkUpdateScope, bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                            generalService: interfaces.applicationcore.IGeneralService) => {

                            $scope.FieldNames = this.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.FieldNames;
                            $scope.FieldNameModel;
                            $scope.Comment;
                            $scope.CurrentReconId = this.cusId;

                            $scope.Close = () => {
                                $modalInstance.dismiss("Cancel");
                            };

                            $scope.Submit = () => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'cus.update'
                                }, () => {
                                    if (!$scope.Comment)
                                    {
                                        this.generalService.displayMessage("Please enter a comment.", Enum.EnumMessageType.Warning);
                                        return;
                                    }

                                    return this.customsService.BulkUpdateSubmit($scope.CurrentReconId, $scope.FieldNameModel, $scope.Comment).save({}, (result) => {
                                        this.generalService.displayMessageHandler(result);
                                        this.loadControls();
                                        $scope.Close();
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    }).$promise;
                                });
                            };
                            
                        }],
                    resolve: {

                    }
                });

                modalInstance.result.then((result: Array<number>) => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'update'
                    }, () => {

                    });
                }, () => {

                });

            }
           
            checkBoxChanged(entity) {
                if (this.apiCusLineDetList) {
                    this.apiCusLineDetList.rowEdit.setRowsDirty([entity]);
                }
                if (this.apiCusHeadList) {

                    if (entity.Comments) {
                        entity.IsMatchedComplete = true;
                    }

                    this.apiCusHeadList.rowEdit.setRowsDirty([entity]);
                }
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }

            getDocuments(showAll:boolean) {
                this.IsLoading = true;
                this.bsLoadingOverlayService.wrap({
                }, () => {

                    if (this.ClearingInstructionRecon) {
                        return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.WaybillNumber, systemType: Enum.EnumSystemType.ImportConsignment, ownerEntityId: this.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.EntityId, showAll:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                            this.documentRepository = documentRepository;
                            this.IsLoading = false;
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    }
                });
            }

            printDocuments() {
                this.$state.go("auth.CustomsRecon.Update.Documents");
            }

            Submit_ButtonClick(close: boolean) {
                this.Submit(close);
            }
            
            Close() {
                this.$state.go("^");
            }
        }

        angular.module("app").controller("clearingInstructionReconDetailUpdate", controllers.customs.clearingInstructionReconDetailUpdate);
    }
}